import { NgIf } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { PfActionButtonComponent } from 'pf-ui';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';

export interface BreadcrumbItem extends MenuItem {
  label: string;
  route?: string;
  action?: () => void;
  visible?: boolean;
}

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [BreadcrumbModule, NgIf, PfActionButtonComponent, ButtonModule],
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent {
  items = input<BreadcrumbItem[]>();
  disabled = input<boolean>(false);

  visibleItems = computed(() =>
    this.items()?.filter((i) => i.visible == undefined || i.visible),
  );

  constructor() {}

  callAction(item: BreadcrumbItem): void {
    if (item.action && !this.disabled()) item.action();
  }

  getItemRoute(item: BreadcrumbItem): string {
    return `/${item.route}`;
  }
}
